<template>
  <div>
    <action-header
        v-if="!noHeader"
        :is-loading="!sender"
        :actions-model="headerModel"
        :page="page"
    />

    <div v-if="(isAdministrator || isSupervisior || isClient) && !simple" class="q-px-md q-pt-md">
      <q-legend :label="$t('General')"/>

      <form-builder :schema="schema"/>
    </div>

    <div class="q-pa-md">
      <q-legend :label="$t('Sender details')"/>

      <c-profile
          :phone-model="clientProfile"
          :is-loading="!clientProfile"
          :model="clientProfile || {}"
          :disabled="!sender"
          @phone="updateProfile"
          @profile="updateProfile"
      />

      <div class="row items-center q-mt-md q-mb-xs">
        <q-legend :label="$t('Sender address')"/>

        <q-btn-group class="q-ml-sm">
          <q-btn
              v-if="!disabledDomestic"
              size="sm"
              :text-color="isInternational ? 'dark' : 'white'"
              :color="isInternational ? 'grey-3' : 'light-blue-9'"
              :icon="isInternational ? undefined : 'done'"
              :disable="false"
              :label="$t('Domestic')"
              @click="isInternational = false"
          />

          <q-btn
              size="sm"
              :text-color="isInternational ? 'white' : 'dark'"
              :color="isInternational ? 'light-blue-9' : 'grey-3'"
              :icon="isInternational ? 'done' : undefined"
              :disable="false"
              :label="$t('International')"
              @click="isInternational = true"
          />
        </q-btn-group>
      </div>

      <c-address
          :is-loading="!address"
          :type="addressType"
          :warning="this.appOptions.senderWarning ? $t('Please Note! Fill the address only in case you want that the New Sender address will differ from the Fulfilment Warehouse address.') : ''"
          :model="address || {}"
          :disabled="isAddressDisabled"
          @address="updateCurrentAddress"
      >
        <base-address
            :is-loading="!address"
            :disabled="isAddressDisabled"
            :address="address || undefined"
            @change="updateCurrentAddress"
        />
      </c-address>
    </div>

    <sticky-bottom-header
        :is-loading="isSaveLoading"
        :is-active="hasChange && !noHeader"
        @back="handleDiscard"
        @save="save"
    />

    <div v-if="noHeader" class="row justify-center items-center q-pa-sm border-top">
      <q-btn
          v-if="!noHeader"
          color="dark"
          text-color="white"
          size="sm"
          class="q-mr-sm"
          :label="$t('Back')"
          no-caps
          unelevated
          @click="handleBack"
      />

      <q-btn
          color="light-blue-9"
          text-color="white"
          :style="noHeader ? 'min-width: 30%;' : ''"
          :size="noHeader ? 'md' : 'sm'"
          :label="$t('Save')"
          no-caps
          unelevated
          @click="save"
      />
    </div>

    <eav-modal ref="eavModal" @submit="handleEAVSubmit"/>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'

// Vuex
import { mapGetters, mapActions, mapMutations } from 'vuex'

// Components
import ActionHeader from './../../components/action-header/ActionHeader'
import BaseAddress from '../../components/clients/BaseAddress.vue'
import EavModal from '../../components/modals/EavModal.vue'
import { getRouteOptions } from '@/apps/app/helpers/helpers'

export default {
  name: 'Sender',
  emits: ['submit'],
  components: {
    CProfile: defineAsyncComponent(() => import('./../../components/clients/Profile.vue')),
    CAddress: defineAsyncComponent(() => import('../../components/clients/Address.vue')),
    ActionHeader,
    BaseAddress,
    EavModal
  },
  props: {
    noHeader: {
      type: Boolean,
      default () {
        return false
      }
    }
  },
  data () {
    return {
      isSaveLoading: false,
      hasChange: false,
      isInternational: true,
      disabledDomestic: false,
      simple: false
    }
  },
  computed: {
    ...mapGetters([
      'appOptions',
      'sender',
      'clientProfile',
      'address',
      'isClient',
      'isSupervisior',
      'isAdministrator'
    ]),
    addressType () {
      let rightTab = this.simple ? 'domestic' : 'international'
      let leftTab = this.simple ? 'international' : 'domestic'

      return this.isInternational
          ? rightTab
          : leftTab
    },
    page () {
      return {
        id: this.sender && this.sender.id,
        name: this.sender && this.sender.id
            ? this.$t('Sender')
            : this.$t('New sender')
      }
    },
    headerModel () {
      if (!this.sender) {
        return []
      }

      return [
        {
          section: 'BackAction',
          className: 'q-pr-sm hide-on-mobile',
          options: [
            {
              id: 'back',
              type: 'button',
              icon: 'arrow_back',
              variant: 'light',
              style: 'white-space: nowrap;',
              label: this.$t('Back'),
              onClick: this.handleBack
            }
          ]
        },
        {
          section: 'Title',
          className: 'q-pa-sm fit--mobile text-min-content',
          options: [
            {
              id: 'title',
              type: 'text',
              className: 'text-subtitle1 row justify-center',
              valueClassName: 'q-px-xs',
              valueStyle: 'white-space: nowrap',
              value: this.sender && this.sender.id
                  ? this.$t('Sender') + ': ' + this.sender.id
                  : this.$t('New sender')
            }
          ]
        },
        {
          section: 'Actions',
          className: 'col-sm-4 row text-center row justify-center',
          options: [
            {
              if: false,
              id: 'eav',
              wrapperClassName: 'q-px-xs',
              type: 'button',
              label: this.$t('EAV'),
              hasIcon: true,
              variant: 'light',
              onClick: () => {
                this.$refs.eavModal.open(this.sender.eav, { entityClass: this.$entities.Orderadmin_DeliveryServices_Entity_Sender })
              }
            }
          ]
        }
      ]
    },
    schema () {
      return {
        isLoading: !this.sender,
        groups: [
          {
            styleClasses: 'row',
            fields: [
              {
                if: this.isSupervisior || this.isAdministrator,
                type: 'multiselect',
                label: this.$t('Owner'),
                value: this.sender && this.sender._embedded.owner,
                hasResetBtn: true,
                required: true,
                wrapperStyleClasses: 'col-12 col-md-6 q-pa-sm',
                customLabel (row) {
                  if (row && typeof row === 'object') {
                    return `${row.name} (${row.id})`
                  }

                  return row
                },
                onScroll: (search, page) => {
                  const query = {
                    per_page: 25,
                    page,
                    search,
                    filter: []
                  }

                  return this.$service.user.getAll(query)
                },
                onChange: (owner) => {
                  this.updateSenderEmbedded({ owner })
                  this.hasChange = true
                }
              },
              {
                if: (this.isClient || this.isAdministrator || this.isSupervisior)
                    && !this.simple,
                type: 'multiselect',
                hasResetBtn: true,
                label: this.$t('Parent sender'),
                value: this.sender && this.sender._embedded.parent,
                wrapperStyleClasses: 'col q-pa-sm',
                customLabel (row) {
                  if (row && typeof row === 'object') {
                    return `${row.name} (${row.id})`
                  }

                  return row
                },
                onScroll: (search, page) => {
                  const query = {
                    per_page: 25,
                    page,
                    search,
                    filter: [
                      { type: 'eq', field: 'state', value: 'active' }
                    ]
                  }

                  return this.$service.sender.getAll(query)
                },
                onChange: (parent) => {
                  this.updateSenderEmbedded({ parent })
                  this.hasChange = true

                  if (!parent) {
                    return
                  }

                  return this.$service.profile.get(parent.id, parent)
                      .then(({ address }) => {
                        if (address) {
                          this.setAddress(address)
                        } else {
                          this.setNewAddress()
                        }
                      })
                }
              }
            ]
          }
        ]
      }
    },
    isAddressDisabled () {
      return !this.clientProfile
    }
  },
  mounted () {
    this.simple = this.appOptions.versionType === 'simple'

    if (this.$route.params.id) {
      return this.$service.profile.get(this.$route.params.id, this.sender)
          .then(({ sender, address, clientProfile }) => {
            this.setSender(sender)

            if (clientProfile) {
              this.setClientProfile(clientProfile)
            } else {
              this.setNewClientProfile()
            }

            if (address) {
              this.setAddress(address)
            } else {
              this.setNewAddress()
            }
          }).catch(error => {
            console.error(error)
            this.addWarningNotification(error)
          })
    }

    if (!this.$route.params.id) {
      this.setNewSender()
      this.setNewClientProfile()
      this.setNewAddress()
      this.updateSender({ serviceSelectionAdapter: 'default-delivery-service' })
      if (this.appOptions.wizardSettings && this.appOptions.wizardSettings.parentSender) {
        this.updateSender({
          parent: this.appOptions.wizardSettings.parentSender
        })
      }
    }
    this.isDisabledDomestic()
  },
  unmounted () {
    this.setSender(null)
    this.setClientProfile(null)
    this.setAddress(null)
  },
  methods: {
    ...mapActions([
      'saveClientProfile',
      'saveSender',
      'saveAddress'
    ]),
    ...mapMutations([
      'updateClientProfile',
      'updateSender',
      'updateAddress',
      'setNewClientProfile',
      'setNewAddress',
      'setNewSender',
      'setClientProfile',
      'setSender',
      'setAddress',
      'updateSenderEmbedded',
      'addWarningNotification',
    ]),
    isDisabledDomestic () {
      const options = getRouteOptions('address')

      if (options.additionalProperties && options.additionalProperties.disabledFor) {
        const disabledFor = options.additionalProperties.disabledFor

        if (disabledFor.appOptions && disabledFor.appOptions.includes(this.appOptions.id)) {
          this.disabledDomestic = true
          return
        }
      }

      return
    },
    handleEAVSubmit (eav) {
      this.updateSender({ eav })
      this.hasChange = true
    },
    updateCurrentAddress (data) {
      this.updateAddress(data)
      this.hasChange = true
    },
    updateProfile (value) {
      this.updateClientProfile(value)
      this.updateSender({ name: value.name })
      this.hasChange = true
    },
    handleDiscard () {
      this.$router.go()
    },
    handleBack () {
      this.$router.back()
    },
    // Save the whole profile - sender, client profile, address
    save () {
      this.isSaveLoading = true

      this.saveSender()
          .then(sender => {
            this.setSender(sender)
            return sender
          })
          .then(() => {
            if (!this.clientProfile.id) {
              this.updateClientProfile({ extId: `sender-${this.sender.id}` })
            }

            return this.saveClientProfile()
          })
          .then(clientProfile => {
            this.setClientProfile(clientProfile)

            if (!this.sender._embedded.defaultSenderProfile) {
              this.updateSender({
                _embedded: {
                  ...this.sender._embedded,
                  defaultSenderProfile: clientProfile
                }
              })

              return this.saveSender()
            }

            return Promise.resolve(this.sender)
          })
          .then(() => {
            const _embedded = {
              ...this.address._embedded
            }

            let hasUpdate = false

            if (!this.address._embedded.profile) {
              hasUpdate = true
              _embedded.profile = this.clientProfile
            }

            if (hasUpdate) {
              this.updateAddress({ _embedded })
            }

            return this.saveAddress()
          })
          .then(address => {
            this.hasChange = false
            this.$emit('submit', this.sender)
            return address
          })
          .finally(() => {
            this.isSaveLoading = false
          })
    }
  }
}
</script>
